.message {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.buttonContainer {
  margin-top: 30px;
  justify-content: space-between;
  display: flex;
}
.cancelButton {
}
.okButton {
  width: 120px;
}

.container {
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  border: solid 1px var(--text-medium);
  background-color: #fff;
  border-radius: 6px;
  justify-content: space-between;
}
