.list {
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.card {
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  padding: 10px 16px;
}
.image {
  padding: 24px;
}

.title {
  font-weight: 600;
  margin-bottom: 4px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.imageContainer {
  display: flex;
  justify-content: center;
}
