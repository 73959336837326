.link {
  color: var(--orange-1);
  cursor: pointer;
  font-size: 18px;
}

.noActivities {
  height: 100vh;
  margin-top: 20px;
}
