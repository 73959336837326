.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.header {
  color: var(--text-dark);
  text-align: center;
  margin-top: 8px;
  margin-bottom: 80px;
  text-transform: uppercase;
}

.subheader {
  color: var(--text-medium);
  margin-top: 32px;
}

.inputLabel {
  color: var(--orange-1);
}
.checkBox {
  display: flex;
  margin-bottom: 16px;
  margin-left: -12px;
}
.checkBox a {
  color: var(--orange-1);
}
.checkboxLabel {
  margin-top: 6px;
  color: var(--text-medium);
}

.button {
  background-color: var(--orange-1);
  height: 42px;
}
.button:hover {
  background-color: var(--orange-1);
}

.buttonLabel {
  color: var(--text-dark);
}
