.container {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.activityList {
  display: grid;
  grid-gap: 16px;
}
@media screen and (max-width: 768px) {
  .activityList {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.activityIndicatorContainer {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleAndCountContainer {
  margin-top: 32px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.activitiesCount {
  font-family: Montserrat;
  font-size: 16px;
  color: #4e5a66;
}

.divider {
  margin: 0 22px;
  width: 1px;
  background-color: #979797;
  height: 19px;
}
