.buttonContainer {
  display: flex;
  justify-content: center;
}
.garminButton {
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 50px;
  width: 247px;
  justify-content: center;
  color: black;
}
.garminImage {
  background-image: url("../../assets/garmin.png");
  height: 40px;
  width: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
