.container {
  position: relative;
  display: flex;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  overflow: hidden;
}

.container::after {
  border-radius: 4px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.inner {
  display: flex;
  flex: 1;
  padding: 12px;
}

.container:hover::after {
  opacity: 1;
}
.name {
  color: var(--text-dark);
}
.desc {
  margin-bottom: 16px;
  color: var(--text-medium);
}
.info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.count {
  color: var(--orange-1);
}
.bottomRow {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
.completeContainer {
  display: flex;
  align-items: center;
}

.completeTick {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.completeText {
  color: var(--green-1);
  text-transform: uppercase;
}
