.container {
  position: relative;
  display: flex;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  overflow: hidden;
  padding: 16px;
}

.container::after {
  border-radius: 6px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.24);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.deleteButton {
  padding: 4px 20px;
  color: var(--error-red);
  background-color: #ffffff;
  align-self: flex-end;
}
