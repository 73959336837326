.container {
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.divider {
  background-color: #eeeeee;
  height: 1px;
  margin: 0 5px;
}
.leaderboardRow {
  display: flex;
  align-items: center;
  color: var(--orange-1);
  height: 40px;
  padding: 8px;
  justify-content: space-between;
}

.rankingAndArrow {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}
.label {
  color: var(--text-dark);
}
