.container {
  position: relative;
  display: flex;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  overflow: hidden;
}

.container::after {
  border-radius: 6px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.24);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.container:hover::after {
  opacity: 1;
}

.inner {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-gap: 20px;
  flex: 1;
  padding: 20px;
}
.image {
  width: 261px;
  height: 221px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.details {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.detailsContainer {
  flex: 1;
}

.title {
  font-family: Montserrat;
  color: #2c353d;
  letter-spacing: 0;
}

.date {
  font-family: Montserrat;
  font-size: 12px;
  color: #4e5a66;
  letter-spacing: 0;
}

.statsContainer {
  flex: 1;
  display: grid;
  margin-top: 16px;
  grid-template-columns: auto auto;
  grid-row-gap: 16px;
}

.visitsContainer {
}

.barContainer {
  flex: 1;
  margin-top: 43px;
}

.newGroundBar {
  display: flex;
  flex: 1;
  height: 30px;
  flex-direction: row;
}
.newGround {
  height: 30px;
  background-color: #67c2c4;
}

.oldGround {
  height: 30px;
  background-color: #d9814a;
  opacity: 0.2;
}

.newGroundLabel {
  font-family: Montserrat;
  margin-top: 10px;
  font-size: 14px;
}

.visitedText {
  color: var(--text-medium);
  margin-bottom: 8px;
}

.divider {
  background-color: #4e5a6622;
  width: 1px;
  margin-right: 28px;
}

.noLandmarksText {
  width: 250px;
  margin-bottom: 10px;
}

.noLandmarksFeedbackText {
  color: var(--orange-2);
}

.viewAll {
  color: var(--text-medium);
  padding-top: 10px;
  margin-left: 30px;
}

.neighbourhoods {
}

.landmarksContainer {
  margin-bottom: 24px;
}

.shareIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 768px) {
  .inner {
    display: grid;
    grid-template-columns: 1fr;
  }
  .image {
    background-size: cover;
    width: auto;
  }
}
