.citiesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subheader {
  color: var(--text-dark);
  margin-bottom: 10px;
}

.newForSubs {
  background-color: var(--orange-1);
  color: #ffffff;
  border-radius: 4px;
  padding: 2px 6px;
}
