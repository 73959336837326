.emailsHeader {
  color: var(--text-dark);
  margin-right: 4px;
}

.accountHeader {
  color: var(--text-dark);
  margin-right: 4px;
  margin-top: 20px;
}

.logoutButton {
  width: 200px;
}

.subscriptionMessage {
  color: var(--text-dark);
  padding-bottom: 8px;
}

.subscriptionButton {
  color: white;
}

.detail {
  color: var(--text-dark);
}

.togglesList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
