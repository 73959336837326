.button {
  width: 100px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 600px;
  padding: 16px 0;
}
