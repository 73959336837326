.button {
  width: 100px;
  background-color: var(--orange-1);
  height: 42px;
  margin-top: 40px;
}

.toggleContainer {
  margin-top: 16px;
}
.button:hover {
  background-color: var(--orange-2);
}

.toggleDescription {
  color: var(--text-medium);
}

.toggles {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
