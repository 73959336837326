.container {
  margin: 0;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  padding-right: 16px;
  padding-left: 16px;
  flex: 1;
  overflow-x: hidden;
}

.contentMaxWidth {
  max-width: unset;
  width: 100vw;
}
