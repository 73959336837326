.container {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr auto 2fr;
  grid-template-areas:
    "header header header"
    ". . ."
    ". authbox ."
    ". . .";
}

.headerContainer {
  grid-area: header;
}
.authBox {
  grid-area: authbox;
  position: relative;
  width: 345px;
  height: 590px;
  border-radius: 6px;
  box-shadow: 0 0 3px rgba(255, 255, 255);
}
.authBoxBG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}
.authBoxBG::before {
  top: -25px;
  left: -25px;
  right: -25px;
  bottom: -25px;
  overflow: hidden;
  display: block;
  position: absolute;
  content: " ";
  filter: blur(8px);
  opacity: 0.5;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  background-image: url("../../assets/onboarding_bg.jpg");
}

.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
