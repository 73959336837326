.map {
  height: 604px;
  background: #eeeeee;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  position: relative;
}

.header {
  color: var(--text-medium);
  margin-top: 12px;
}
.subHeader {
  color: var(--text-medium);
  margin-bottom: 12px;
}
.summaryContainer {
  display: flex;
  flex-direction: column;
}
.summaryBar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin: 10px 0;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.summaryMessage {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px;
  flex: 0;
  align-self: flex-start;
}
.summaryMessageInner {
  display: flex;
}
.summaryTextIcon {
}
.summaryMessageText {
  margin-left: 12px;
}

.progressBarContainer {
  margin: 0 20px;
  flex: 1;
}

.progressBarBackground {
  display: flex;
  flex: 1;
  border-radius: 20px;
  height: 8px;
  background-color: #d8d8d8;
  border: #979797 solid 1px;
  overflow: hidden;
}
.progressBarFill {
  display: flex;
  background-color: var(--orange-1);
  border-radius: 0 20px 20px 0;
}

.progressFillHighlight {
  background-color: #ffffffaa;
  height: 1px;
  border-radius: 5px;
  flex: 1;
  margin: 0 4px;
  margin-top: 3px;
}

.icon {
  height: 44px;
  width: 44px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
}
.landmarksHeader {
  margin-top: 28px;
  color: var(--text-medium);
}
.landmarksGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;
  margin-top: 12px;
  margin-bottom: 100px;
}
.completeMessage {
  align-items: center;
  display: flex;
}
.completeTick {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
@media screen and (max-width: 768px) {
  .map {
    height: 50vh;
    position: relative;
  }
}
