.container {
  display: flex;
  align-items: center;
}
.neighbourhood {
  color: var(--text-dark);
  padding: 3px 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
