.areaList {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  padding-bottom: 40px;
  margin-top: 18px;
}

.title {
  color: var(--text-medium);
}
.headerRight {
  text-align: right;
  color: var(--orange-1);
}
