.container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}
.tickImage {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
}
