.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  margin-top: 32px;
  margin-bottom: 24px;
}

.subHeader {
  margin-bottom: 20px;
}

.contentHeader {
  font-weight: bold;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 12px;
}

.contentText {
  text-align: left;
}
