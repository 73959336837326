.heading {
  align-items: center;
  margin-top: 16px;
  color: var(--text-medium);
  margin-bottom: 6px;
}

.container {
  padding: 8px;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
}

.groupHeader {
  color: var(--text-medium);
  padding: 4px;
}

.noGroupsText {
  color: var(--text-medium);
}
