.landmark {
  display: flex;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;
  max-width: 60vw;
}

.landmarkName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.landmark_icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 8px;
}
