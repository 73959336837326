.priceCard {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.pricesContainer {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  text-align: center;
  gap: 20px;
}
