.container {
  height: 150px;
  padding: 20px;
  background-color: #000000;
}
.linkListsContainer {
  display: flex;
}
.linkList {
  flex: 1;
  margin-right: 100px;
}
.link {
  text-decoration: none;
  color: #eeeeee;
  margin: 8px 0;
  cursor: pointer;
}

.link:hover {
  color: #ffffff;
}
