.groupList {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 16px;
  padding-bottom: 40px;
}

.noGroups {
  color: var(--text-medium);
}

.joinText {
  color: var(--orange-1);

  margin-bottom: 16px;
}
