:root {
  --text-dark: #2c353d;
  --text-medium: #4e5a66;
  --orange-1: #d9814a;
  --orange-2: #f79c40;
  --orange-3: #fd7f00;
  --green-1: #18a0a0;
  --green-2: #67c2c4;
  --purple: #cc0cf0;
  --error-red: #ff0000;
  --green: #18a0a0;
  --ion-background-color: #f8f8f8;
  --ion-color-primary: #d9814a;
}

html {
  background: #f4f4f4;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.heading1 {
  font-family: Montserrat;
  font-size: 36px;
}

.heading2 {
  font-family: Montserrat;
  font-size: 24px;
}

.heading3 {
  font-family: Montserrat;
  font-size: 20px;
}

.heading4 {
  font-family: Montserrat;
  font-size: 16px;
}

.heading5 {
  font-family: Montserrat;
  font-size: 12px;
}

.text {
  font-family: Montserrat;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .heading1 {
    font-family: Montserrat;
    font-size: 22px;
  }

  .heading2 {
    font-family: Montserrat;
    font-size: 20px;
  }
  .heading3 {
    font-family: Montserrat;
    font-size: 18px;
  }
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 200%;
  display: inline-block;
  position: relative;

  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

.link {
  color: var(--orange-1);
  text-align: right;
  cursor: pointer;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: -100% 0%;
  }
}
