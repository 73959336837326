.container {
  height: 64px;
  background: #f5f5f5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: static;
}

.innerContent {
  height: 64px;
  display: flex;
  align-items: center;
}

.contentContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.logo {
  font-family: Montserrat-Regular;
  font-size: 24px;
  color: #d9814a;
  letter-spacing: 0;
  margin-right: 20px;
  cursor: pointer;
}

.link {
  font-family: Montserrat;
  font-size: 16px;
  color: var(--text-dark);
  letter-spacing: 0;
  margin: 0 20px;
  cursor: pointer;
  transition: 0.15s;
}
.link:hover {
  color: var(--orange-3);
}

.menuItemsContainer {
  display: flex;
  flex: 1;
}

.nameAndSettings {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nameText {
  color: var(--text-medium);
}

.settings {
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 10px;
}
.logoMobile {
}
.signInButton {
  background-color: var(--orange-1);
}
.signInButtonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .logoMobile {
    display: none;
  }
  .logo {
    margin-right: 0;
  }
  .link {
    margin: 0 10px;
  }
  .nameText {
    display: none;
  }
}
