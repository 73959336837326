.container {
  display: grid;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  min-height: 100px;
  padding: 20px;
  grid-gap: 20px;
  grid-template-columns: auto 1fr;
}

.image {
  width: 261px;
  height: 221px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.topNeighbourhoodsHeader {
  margin-bottom: 8px;
  color: var(--text-dark);
}
.neighbourhood {
  display: flex;
  margin: 5px 0;
}

.thumbnail {
  width: 261px;
  height: 221px;
  background-color: #fafafa;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.percentValue {
  min-width: 54px;
  color: var(--orange-1);
  font-weight: bold;
}

.name {
  color: var(--text-dark);
}
.hyphen {
  margin: 0 10px;
  color: var(--text-medium);
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }
  .thumbnail {
    background-size: contain;
    width: auto;
  }
}
