.header {
  color: var(--text-medium);
  margin-top: 12px;
}

.map {
  height: 55vh;
  background: #eeeeee;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  position: relative;
}

.mapContainer {
}

.exploredBox {
  pointer-events: none;
  z-index: 9;
  position: absolute;
  bottom: 10px;
  left: 1px;
  padding: 16px;
  background: rgba(217, 215, 215, 0.52);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 10px;
}

.selectContainer {
  height: 0;
  position: relative;
  z-index: 10;
  top: 8px;
  left: 8px;
}
.highlightLabel {
  color: var(--text-medium);
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.highlightBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .highlightBar {
    flex-direction: column;
    align-items: initial;
    gap: 8px;
  }
}
.moreDetailsMessage {
  color: var(--text-medium);
}
