.container {
  background-color: #ffffff;
  color: var(--text-dark);
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 8px;
  align-items: center;
}
.highlightText {
  color: var(--orange-1);
}

.message {
  color: var(--text-medium);
  font-weight: 600;
}
