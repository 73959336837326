.position {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  width: 300px;
  height: 400px;
  flex-direction: column;
  background-color: #eeeeee;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.header {
  color: var(--text-medium);
  margin-bottom: 4px;
}

.subHeading {
  color: var(--text-medium);
  margin-bottom: 20px;
}

.textInput {
  background-color: #ffffff;
  flex: 1;
  resize: none;
  font-family: Montserrat;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.categoryContainer {
  display: flex;
  margin-top: 16px;
  gap: 8px;
  align-items: center;
}

.categoryText {
  color: var(--text-medium);
  margin-right: 10px;
}

.categorySelect {
  margin-top: 1px;
  width: 100px;
}
