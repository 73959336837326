.header {
  display: flex;
  padding: 16px 0;
}

.memberRow {
  padding: 16px 0;
  border-bottom: 1px solid #e0e0e0;
}

.keyStatsContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background-color: #ffffff;
  margin: 16px;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.keyStatBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.keyStatLabel {
  text-align: center;
  color: var(--text-medium);
}

.keyStatValue {
  text-align: center;
  color: var(--orange-1);
}

.leaderboardLinksContainer {
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.menuItem {
  color: var(--text-dark);
}

.delete {
  color: var(--error-red);
}
