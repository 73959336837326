.buttonContainer {
  display: flex;
  justify-content: center;
}
.stravaButton {
  background-image: url("../../assets/strava.svg");
  height: 64px;
  width: 270px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
