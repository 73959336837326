.container {
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  border: solid 1px var(--text-medium);
  background-color: #fff;
  border-radius: 6px;
  justify-content: space-between;
  min-width: 200px;
}
.heading {
  font-weight: 600;
}
.exampleHeading {
  font-weight: 600;
}

.example {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #eeeeee;
  font-family: "MaisonNeue", "Segoe UI", "Helvetica Neue", -apple-system,
    system-ui, BlinkMacSystemFont, Roboto, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.buttonContainer {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.item {
  opacity: 0.3;
}
.active {
  opacity: 1;
  color: var(--text-dark);
}
