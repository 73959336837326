.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.progressContainer {
  margin-top: 40px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 30px;
}

.errorContainer {
  text-align: center;
}
