.row {
  display: grid;
  border-bottom: solid #eeeeee 1px;
  margin: 0 8px;
  align-items: center;
  grid-template-columns: 55px auto 1fr 80px;
  padding: 8px 0;
}

.highlight {
  background-color: var(--orange-1);
}

.headingContainer {
  padding: 8px;
}
.heading {
  color: white;
  font-weight: bold;
  text-align: center;
}

.topSection {
  background-color: var(--orange-2);
  padding-bottom: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.keyStatsContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background-color: #ffffff;
  margin: 16px;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.keyStatBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.keyStatLabel {
  text-align: center;
  color: var(--text-medium);
}

.keyStatValue {
  text-align: center;
  color: var(--orange-1);
}

.divider {
  width: 1px;
  background-color: #eeeeee;
  height: 30px;
}
.rank {
  color: var(--text-dark);
  text-align: center;
}

.name {
  color: var(--text-dark);
  padding: 0 16px;
}

.percent {
  text-align: center;
  color: var(--orange-1);
}
.percentDark {
  text-align: center;
  color: var(--text-medium);
}

.leaderboardHeader {
  font-weight: bold;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.gap {
  height: 50px;
  background-color: #dddddd;
}

.notInLeaderboard {
  text-align: center;
  padding: 16px;
  color: var(--text-medium);
}

.noEntries {
  text-align: center;
  padding: 16px;
  color: var(--text-medium);
}
.totalContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: solid #cccccc 1px;
  padding: 8px;
  text-align: center;
  background-color: white;
}
.totalText {
  color: var(--text-dark);
}
