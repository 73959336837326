.mainLabel {
    font-family: Montserrat;
    font-size: 18px;
    color: #d9814a;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
}

.label {
    font-family: Montserrat;
    font-size: 18px;
    color: #d9814a;
    letter-spacing: 0;
    text-align: center;
}