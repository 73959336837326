.container {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.containerIos {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}
.message {
  padding-right: 16px;
  color: var(--text-dark);
  text-align: center;
}
