.fullscreenIcon {
  height: 40px;
  width: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 4px;
  right: 4px;
  background: rgba(217, 215, 215, 0.82);
  z-index: 100000;
  cursor: pointer;
  transform: translateZ(0);
}
.fullscreen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  z-index: 100000000;
}
