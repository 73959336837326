.container {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  padding-bottom: env(safe-area-inset-bottom, 20px);
}

.waiting {
  display: flex;
  flex-direction: row;
  color: var(--text-medium);
}

.header {
  color: var(--text-medium);
  margin-top: 18px;
}
.subHeader {
  color: var(--text-medium);
  margin-bottom: 24px;
  margin-left: 2px;
}

.map {
  height: 100%;
  background: #eeeeee;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .map {
    height: 100%;
  }
  .header {
    margin-bottom: 12px;
  }
  .subHeader {
    display: none;
  }
}

.instruction {
  margin-top: 12px;
  margin-bottom: 12px;
}

.buttonContainer {
  display: flex;
  height: 42px;
  align-items: center;
}
.button {
  background-color: var(--orange-1);

  width: 100px;
  margin-left: 12px;
}
.button:hover {
  background-color: var(--orange-1);
}

.buttonLabel {
  color: var(--text-dark);
}

.inputAndButton {
  display: flex;
  margin-top: 8px;
  margin-bottom: 12px;
  align-items: center;
}
