.wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 1px;
}
.border {
  display: flex;
  margin: 10px;
}
.container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  height: 487px;
  width: 325px;
  margin: 10px;
  cursor: pointer;
}

.statsContainer {
  margin-top: 10px;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: auto auto;
}

.inner {
  padding: 20px;
}

.cityName {
  color: var(--text-dark);
  margin-top: 10px;
}

.thumbnail {
  position: relative;
  flex: 1;
  height: 220px;
  background-color: #eeeeee;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.custom {
  border-radius: 3px;
  background-color: var(--orange-1);
  color: #fff;
  position: absolute;
  top: 4px;
  right: 4px;
  font-weight: bold;
  padding: 3px;
  text-transform: uppercase;
}
