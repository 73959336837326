.container {
  background-color: #f4f4f4;
  width: 100vw;
}
.contentContainer {
  padding: 0 20px;
}
.mapContainer {
}

.selectContainer {
  height: 0;
  position: relative;
  z-index: 10;
  top: 8px;
  left: 8px;
}
.exploredBox {
  pointer-events: none;
  z-index: 9;
  position: absolute;
  bottom: 10px;
  left: 1px;
  padding: 16px;
  background: rgba(217, 215, 215, 0.52);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 80px;
}

.valueContainer {
}

.map {
  height: 604px;
  background: #eeeeee;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .contentContainer {
    padding: 0;
  }
  .map {
    height: 50vh;
    position: relative;
  }
  .exploredBox {
    padding: 10px;
  }
  .exploredValue {
    font-size: 18px;
  }
  .exploredLabel {
    font-size: 18px;
  }
}

.message {
  background-color: #ffffff;
  color: var(--text-dark);
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 6px;
}
