.grid {
  display: grid;
  grid-template-columns: 2.7fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin-bottom: 8px;
}
.premiumText {
  color: var(--orange-1);
}
.freeText {
  color: var(--text-dark);
}
.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: #f1f1f1;
  height: 100%;
}

.cellPremium {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: #d8d8d8;
  height: 100%;
}
.cellLabel {
  padding: 6px;
}
