.map {
  height: 604px;
  background: #eeeeee;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .map {
    height: 50vh;
  }
}
