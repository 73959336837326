.container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  height: 340px;
}

.container::before {
  border-radius: 6px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.container:hover::before {
  opacity: 1;
}
.inner {
  padding: 20px;
}

.addIconContainer {
  display: flex;
  flex: 1;
  height: 220px;
  align-items: center;
  justify-content: center;
}

.addIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 160px;
  border-radius: 80px;
  background-color: #d8d8d855;
  color: var(--orange-1);
  font-size: 100px;
  line-height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: initial;
}

.cityName {
  color: var(--text-dark);
  margin-top: 10px;
  text-align: center;
}
