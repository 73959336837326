.position {
  position: relative;
  width: 100%;
  height: 100%;
}
.container {
  position: absolute;
  width: 180px;
  background-color: #eeeeee;
  padding: 24px;
  top: 70px;
  border-radius: 4px;
  right: calc(max((100vw - 1024px)/2, 16px));
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.menuItem {
  font-family: Montserrat;
  font-size: 16px;
  color: var(--text-dark);
  letter-spacing: 0;
  margin: 0 20px;
  cursor: pointer;
  transition: 0.15s;
}
.menuItem:hover {
  color: var(--orange-3);
}
