.priceCard {
  margin-top: 8px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  padding: 16px;
  cursor: pointer;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonSubheading {
  color: var(--orange-1);
  min-height: 20px;
}
.heading {
  color: var(--text-dark);
}
.price {
  margin: 16px 0;
  color: var(--orange-1);
  font-weight: bold;
  text-align: center;
}

.priceText2 {
  color: var(--text-dark);
  margin-bottom: 16px;
}
.stripeLogo {
  margin-top: 28px;
}

.then {
  color: var(--text-medium);
  font-weight: 400;
  margin-top: 16px;
}
