.suggest {
  color: var(--orange-1);
  cursor: pointer;
}

.city {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 8px;
  text-align: center;
  color: var(--text-medium);
}

.citiesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 8px;
  padding-bottom: 20px;
}
