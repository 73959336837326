.statContainer {
  margin-right: 20px;
}

.statLabel {
  font-family: Montserrat;
  font-size: 16px;
  color: #4e5a66;
  letter-spacing: 0;
  white-space: nowrap;
}

.statValue {
  color: var(--text-dark);
}
.statValueOrange {
  color: var(--orange-1);
}

.statValueComplete {
  display: flex;
  color: var(--green);
  align-items: center;
}
.statValueLoading {
  height: 24px;
  width: 100px;
  margin-top: 2px;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}
