.container {
  border-radius: 6px;
  border: 1px solid #979797;
  overflow: hidden;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
}

.button {
  background-color: #f4f4f4;
  height: 32px;
}

.buttonLabelSelected {
  font-family: Montserrat;
  color: #2c353d;
  padding: 0 16px;
}

.buttonLabel {
  font-family: Montserrat;
  color: #4e5a66;
  padding: 0 16px;
}

.buttonSelected {
  height: 32px;
  background-color: var(--orange-2) !important;
}

@media screen and (max-width: 768px) {
  .buttonLabelSelected {
    padding: 0 10px;
  }

  .buttonLabel {
    padding: 0 10px;
  }
}
