.container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.container::after {
  border-radius: 6px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.container:hover::after {
  opacity: 1;
  pointer-events: none;
}

.inner {
  padding: 20px;
}

.neighbourhoodName {
  color: var(--text-dark);
  margin-top: 10px;
}
.name {
  color: var(--text-dark);
  margin-top: 10px;
}

.thumbnail {
  position: relative;
  flex: 1;
  height: 220px;
  background-color: #eeeeee;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.statsContainer {
  margin-top: 10px;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: auto auto;
}

.shareIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  padding: 8px;
}
