.header {
  color: var(--text-medium);
  margin-top: 18px;
  margin-bottom: 28px;
  margin-left: 4px;
  margin-right: 4px;
}

.achievementsList {
  margin-top: 12px;
  margin-bottom: 20px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
