.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  color: var(--text-medium);
  margin-bottom: 6px;
}

.right {
  cursor: pointer;
  color: var(--orange-1);
  text-align: right;
}
