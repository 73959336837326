.detailsText {
  margin-left: 4px;
  color: var(--orange-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.landmarkGrid {
  padding: 8px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  overflow: hidden;
}
