.container {
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 15px;
  border-top: 1px #444444 solid;
}

.header {
  color: var(--text-medium);
}

.textContainer {
  margin-top: 25px;
  display: flex;
}

.mainText {
  color: var(--text-medium);
}

.privacyText {
  margin-left: 5px;
  color: var(--orange-1);
}

.acceptButton {
  margin-right: 20px;
}

.reject {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: normal;
}

.rejectText {
  font-weight: normal;
}

.learnMore {
  color: var(--orange-1);
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.acceptRejectButtons {
  display: flex;
  width: 200px;
}
