.container {
  display: flex;
  flex-direction: column;
}

.map {
  height: 604px;
  background: #eeeeee;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  position: relative;
}

.header {
  color: var(--text-medium);
  margin-top: 18px;
  margin-bottom: 28px;
}

.subheader {
  color: var(--text-medium);
  text-transform: capitalize;
}

.statsContainer {
  display: flex;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 10px;
}

.divider {
  background-color: #4e5a6622;
  width: 1px;
  margin-right: 50px;
  margin-left: 22px;
}

.noVisitsText {
  color: var(--text-dark);
}
.activitiesContainer {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .header {
    margin-top: 6px;
    margin-bottom: 14px;
  }
  .map {
    height: 50vh;
    position: relative;
  }
  .activityList {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .divider {
    margin-right: 20px;
    margin-left: 0;
  }
}
