.container {
  display: flex;
  align-items: center;
}
.label {
  color: var(--text-dark);
  text-align: left;
}

.value {
  margin-left: 10px;
  color: #d9814a;
  text-align: center;
}

.complete {
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: var(--green);
}
