.container {
  display: grid;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  min-height: 100px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 20px;
}

.section {
  flex: 1;
}
.sectionHeader {
  margin-bottom: 4px;
  color: var(--text-dark);
}

.landmarkContainer {
  display: flex;
  align-items: center;
}

.detailsText {
  margin-left: 4px;
  color: var(--text-medium);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.header {
  color: var(--text-medium);
  margin-bottom: 10px;
  margin-top: 10px;
}
