.dashboardHeader {
  display: grid;
  grid-template-columns: calc(100% - 100px) 40px 40px; /* Would to use 1fr but that wont limit the dropdowns width effectively*/
  grid-gap: 8px;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.viewAllLink {
  display: flex;
  align-items: center;
  font-family: Montserrat-Regular;
  font-size: 12px;
  color: #4e5a66;
  letter-spacing: 0;
  text-align: center;
  text-decoration: none;
}

.dropdownContainer {
  min-width: 0;
  margin-top: 22px;
  margin-bottom: 14px;
  gap: 4px;
}

.menuItem {
  color: var(--text-dark);
}
@media screen and (max-width: 768px) {
  .dropdownContainer {
    margin-top: 10px;
    margin-bottom: 8px;
  }
}
