.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.logoMobile {
  display: none;
  height: 64px;
  background: #f5f5f5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding-left: 16px;
}

.header {
  margin-top: 18px;
  margin-bottom: 8px;
  color: var(--text-medium);
}

.subHeader {
  margin-bottom: 10px;
  color: var(--text-medium);
}

.topHeaderContainer {
  margin-top: 18px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.firstImageAndAuthContainer {
  display: grid;
  grid-template-columns: 1fr auto;
}

.authBox {
  margin-left: 12px;
  border: 1px solid;
  border-color: var(--text-medium);
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f4f4f4;
  box-shadow: 0 0 10px #00000033;
  padding: 10px;
}
.topImage {
  width: 100%;

  max-width: 599px;
}
.imgShadow {
  box-shadow: 0 0 10px #00000033;
}

.cityList {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 4px;
  grid-template-rows: 1fr;
  grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */
  overflow-y: hidden; /* hide grid items that overflow */
  margin-left: -10px;
}

.activityList {
  margin-bottom: 20px;
  margin-top: 0;
  display: grid;
  grid-gap: 16px;
}

.imageWithShadow {
  margin-left: -10px;
  margin-right: -10px;
  max-width: calc(100% + 20px);
}

.authBoxDesktop {
}
.authBoxMobile {
  display: none;
}

.joinButtonMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .cityList {
    margin-left: 0;
  }
  .activityList {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.footer {
  margin-top: 40px;
}

.leaderboardContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;

  gap: 16px;
}

.desktopOnly {
}

@media screen and (max-width: 600px) {
  .desktopOnly {
    display: none;
  }
  .logoMobile {
    display: block;
  }
  .joinButtonMobile {
    display: initial;
  }

  .imageWithShadow {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
  .firstImageAndAuthContainer {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
  .authBoxDesktop {
    display: none;
  }
  .authBoxMobile {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
