.container {
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.landmarksContainer {
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
  min-height: 80vh;
}

.landmarksHeader {
  flex: 0;
  color: var(--text-dark);
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
}

.landmark {
  margin: 15px 5px;
}

.mobileDropDown {
  display: none;
}
@media screen and (max-width: 1024px) {
  .mobileDropDown {
    display: block;
  }
  .toggleContainer {
    display: none;
  }
}
