.header {
  color: var(--text-medium);
  margin-top: 12px;
}
.subHeader {
  color: var(--text-medium);
  margin-bottom: 24px;
}

.container {
  padding-bottom: 20px;
}
.sectionHeader {
  margin-bottom: 4px;
}
.statsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px 0;
}
.statsContainer > * {
  margin-bottom: 10px;
}

.landmarksContainer {
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.landmarkContainer {
  display: flex;
  align-items: center;
}

.detailsText {
  margin-left: 4px;
  margin-top: 8px;
  color: var(--text-medium);
}

.detailsTextHighlighted {
  margin-left: 4px;
  margin-top: 8px;
  color: var(--orange-1);
}

.neighbourhoods {
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.menuItem {
  color: var(--text-dark);
}
.notSupported {
  padding: 40px 20px;
}

@media screen and (max-width: 768px) {
  .header {
    margin-top: 6px;
  }
  .subHeader {
    margin-bottom: 12px;
  }
}
