.container {
  margin: 10px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  height: 427px;
  cursor: pointer;
}

.container::after {
  border-radius: 6px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  height: 100%;
}

.container:hover::after {
  opacity: 1;
}
