.container {
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  border: solid 1px var(--text-medium);
  background-color: #fff;
  border-radius: 6px;
  justify-content: space-between;
}
.message {
  text-align: center;
}

.grantButton {
  margin-top: 20px;
}
