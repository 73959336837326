.link {
  color: var(--text-dark);
  cursor: pointer;
  font-size: 18px;
}

.linkList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
}
