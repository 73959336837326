.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.authHeader {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
  color: var(--text-medium);
}

.authMessage {
  margin-top: 20px;
  text-align: center;
  color: var(--text-medium);
}
.authMessageContainer {
  display: flex;
  margin-top: 6px;
}

.platformMessage2 {
  color: var(--text-medium);
  padding: 0 20px;
  text-align: center;
}

.highlight {
  color: var(--orange-1);
  cursor: pointer;
}
