.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verifyMessage {
  color: var(--text-dark);
  margin: 20px 20px;
}

.button {
  background-color: var(--orange-1);
  height: 42px;
}
.button:hover {
  background-color: var(--orange-1);
}

.buttonLabel {
  color: var(--text-dark);
}
