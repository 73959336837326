.container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  height: 340px;
}

.container::before {
  border-radius: 6px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.container:hover::before {
  opacity: 1;
}
.inner {
  padding: 20px;
}

.thumbnail {
  position: relative;
  flex: 1;
  height: 220px;
  background-color: #eeeeee;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.cityName {
  color: var(--text-dark);
  margin-top: 10px;
}
.buttons {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.deleteButton {
  border: var(--error-red) 2px solid;
  border-radius: 8px;
  text-align: center;
  padding: 4px 20px;
  color: var(--error-red);
  cursor: pointer;
  background-color: #ffffff;
}
